module.exports = [{
      plugin: require('../node_modules/.pnpm/gatsby-remark-autolink-headers@6.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-_mxjwivpllnvllg2jw34popz5ku/node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-remark-images@7.13.2_gatsby-plugin-sharp@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_esli_owatx3ntwraxiu644lvj7ip66q/node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":630,"linkImagesToOriginal":true,"showCaptions":false,"markdownCaptions":false,"backgroundColor":"white","quality":50,"withWebp":false,"withAvif":false,"loading":"lazy","decoding":"async","disableBgImageOnAlpha":false,"disableBgImage":false},
    },{
      plugin: require('../node_modules/.pnpm/gatsby-plugin-manifest@5.13.1_gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3_23bwlxez5pcwligfv4cb4laigy/node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Gatsby Starter Blog","short_name":"Gatsby","start_url":"/","background_color":"#ffffff","display":"minimal-ui","icon":"src/images/gatsby-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d3b437450b8481993a50b7829b5bb282"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/.pnpm/gatsby@5.13.7_babel-eslint@10.1.0_eslint@7.32.0__react-dom@18.3.1_react@18.3.1__react@18.3.1__62rlhzwa3jwkalmjt572b6cgau/node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
